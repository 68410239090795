import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    acreditados: []
  },
  mutations: {
    set_accion_acreditados (state, acreditados) {
      state.acreditados = acreditados
    }
  },
  actions: {
    async getAcreditados ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `http://${store.state.host}/api/iss/getAcreditados`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getAcreditados')
          console.log(response.data)
          commit('set_accion_acreditados', response.data)
          return true
        } else {
          console.log('por else getAcreditados')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAcreditados')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
