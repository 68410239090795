import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    visitasE18: []
  },
  mutations: {
    set_accion_visitasE18 (state, visitasE18) {
      state.visitasE18 = visitasE18
    }
  },
  actions: {
    async getVisitasE18 ({ commit, dispatch, state }, periodo) {
      try {
        const response = await axios({
          method: 'post',
          url: `http://${store.state.host}/api/iss/indicador?form_code=E18`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: periodo
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getVisitasE18')
          console.log(response.data)
          commit('set_accion_visitasE18', response.data)
          return true
        } else {
          console.log('por else getVisitasE18')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getVisitasE18')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
