import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    dotacion: []
  },
  mutations: {
    set_accion_dotacion (state, dotacion) {
      state.dotacion = dotacion
    }
  },
  actions: {
    async getDotacion ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `http://${store.state.host}/api/iss/getDotacion`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getDotacion')
          console.log(response.data)
          commit('set_accion_dotacion', response.data)
          return true
        } else {
          console.log('por else getDotacion')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getDotacion')
        router.push({ path: '/' })
        return false
      }
    },
    async updateDotacion ({ commit, dispatch, state }, dotacionUpdate) {
      try {
        const response = await axios({
          method: 'put',
          url: `http://${store.state.host}/api/iss/updateDotacion`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: dotacionUpdate
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api updateDotacion')
          console.log(response.data)
          commit('set_accion_dotacion', response.data)
          return true
        } else {
          console.log('por else updateDotacion')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateDotacion')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
