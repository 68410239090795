import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    asistenciaPersonal: []
  },
  mutations: {
    set_accion_asistenciaPersonal (state, asistenciaPersonal) {
      state.asistenciaPersonal = asistenciaPersonal
    }
  },
  actions: {
    async getAsistenciaPersonal ({ commit, dispatch, state }, periodo) {
      try {
        const response = await axios({
          method: 'post',
          url: `http://${store.state.host}/api/iss/getTrabajadores`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: periodo
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getAsistenciaPersonal')
          console.log(response.data)
          commit('set_accion_asistenciaPersonal', response.data)
          return true
        } else {
          console.log('por else getAsistenciaPersonal')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAsistenciaPersonal')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
