import { mapActions, mapState } from 'vuex'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
import PieChart from '@/components/Layout/Common/Graficos/PieChart/PieChart.vue'
// import store from '@/store'

export default {
  name: 'ReporteAsistencias',
  components: {
    PieChart
  },
  props: {
  },
  data: function () {
    return {
      fields: [
        // {
        //   key: 'site_id',
        //   label: 'ID Sitio',
        //   sortable: false
        // },
        // {
        //   key: 'site_code',
        //   label: 'Codigo Sitio',
        //   sortable: false
        // },
        {
          key: 'nombre',
          label: 'Nombre',
          sortable: false
        },
        // {
        //   key: 'direccion',
        //   label: 'Direccion',
        //   sortable: false
        // },
        {
          key: 'ultima_visita',
          label: 'Ultima Visita',
          sortable: false
        },
        {
          key: 'visitas_mensuales',
          label: 'Cantidad Visitas',
          sortable: false
        }
        // {
        //   key: 'visitado',
        //   label: 'Visitado',
        //   sortable: false
        // }
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      backgroundColor: '#91c27d',
      año_mes: '',
      meses: [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
      ],
      mesStr: 'Enero',
      render: '',
      label: ['Visitado', 'No Visitado'],
      datasets: [],
      pieBackgroundColor: [
        'rgb(66, 98, 185)',
        'rgb(255, 0, 0)'
      ]
    }
  },
  watch: {
  },
  mounted () {
    const fecha = new Date()
    this.mesStr = this.meses[fecha.getMonth()]
    const año = fecha.getFullYear()
    let mes = fecha.getMonth() + 1
    if (mes < 10) {
      mes = '0' + mes
    }
    this.año_mes = año + '-' + mes
    // console.log(this.año_mes)
    this.getSitiosLosHeores(this.año_mes).then((res) => {
      this.items = this.sitiosLosHeores
      this.totalRows = this.items.length
    })
    this.getIndicadorMensual(this.año_mes).then((res) => {
      // console.log(this.indicadorMensual)
      this.datasets = []
      this.datasets.push(this.indicadorMensual.visitados.toString())
      this.datasets.push(this.indicadorMensual.no_visitados.toString())
      this.label = []
      this.label.push('Visitadas: ' + (this.indicadorMensual.porcentaje_visitados).toFixed(1) + '%')
      this.label.push('No Visitadas: ' + (this.indicadorMensual.porcentaje_no_visitados).toFixed(1) + '%')
      this.render = this.datasets
    })
  },
  methods: {
    ...mapActions('ReporteAsistencias', [
      'getIndicadorMensual', 'getSitiosLosHeores'
    ]),
    info (item, index, button) {
      this.infoModal.title = 'Editar'
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    hideModal () {
      this.$refs[this.infoModal.id].hide()
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        autoHideDelay: 5000
      })
    }
  },
  computed: {
    ...mapState('ReporteAsistencias', ['sitiosLosHeores']),
    ...mapState('ReporteAsistencias', ['indicadorMensual']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
