import Vue from 'vue'
import Vuex from 'vuex'
import Files from '@/modules/Files.js'
import Acreditados from '@/modules/Acreditados.js'
import ReporteVisitas from '@/modules/ReporteVisitas.js'
import ReporteAseosGenerales from '@/modules/ReporteAseosGenerales.js'
import Dotacion from '@/modules/Dotacion.js'
import AsistenciaPersonal from '@/modules/AsistenciaPersonal.js'
import ReporteAsistencias from '@/modules/ReporteAsistencias.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: '',
    password: '',
    token: 'token',
    host: 'localhost:8098',
    hostAws: 'https://api.appdmetrix.com'
    // host: 'app.vps.dmetrix.cl:8090'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Files,
    Acreditados,
    ReporteVisitas,
    ReporteAseosGenerales,
    Dotacion,
    AsistenciaPersonal,
    ReporteAsistencias
  }
})
