import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
// import MenuView from '../views/MenuView.vue'
// import AcreditacionView from '@/components/Layout/Menu/Acreditacion/AcreditacionView.vue'
// import AsistenciaPersonalView from '@/components/Layout/Menu/Asistencia_personal/AsistenciaPersonalView.vue'
// import ReporteAseosGeneralesView from '@/components/Layout/Menu/Reportes/Reporte_aseos_generales/ReporteAseosGeneralesView.vue'
// import ReporteVisitasView from '@/components/Layout/Menu/Reportes/Reporte_visitas/ReporteVisitasView.vue'
// import DotacionView from '@/components/Layout/Menu/Dotacion/DotacionView.vue'
// import ArchivosView from '@/components/Layout/Menu/Archivos/ArchivosView.vue'
import ReporteAsistenciasView from '@/components/Layout/Menu/Reportes/Reporte_asistencias/ReporteAsistenciasView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'ReporteAsistecias',
    component: ReporteAsistenciasView
  }
  // {
  //   path: '/archivos',
  //   name: 'archivos',
  //   component: ArchivosView
  // },
  // {
  //   path: '/menu',
  //   name: 'menu',
  //   component: MenuView
  // },
  // {
  //   path: '/asistencia',
  //   name: 'asistencia',
  //   component: AsistenciaPersonalView
  // },
  // {
  //   path: '/acreditacion',
  //   name: 'acreditacion',
  //   component: AcreditacionView
  // },
  // {
  //   path: '/reporteVisitas',
  //   name: 'reporteVisitas',
  //   component: ReporteVisitasView
  // },
  // {
  //   path: '/reporteAseosGenerales',
  //   name: 'reporteAseosGenerales',
  //   component: ReporteAseosGeneralesView
  // },
  // {
  //   path: '/dotacion',
  //   name: 'dotacion',
  //   component: DotacionView
  // },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
