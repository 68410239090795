import store from '@/store'
import axios from 'axios'
// import router from '@/router/'

export default {
  namespaced: true,
  state: {
    indicadorMensual: [],
    sitiosLosHeores: []
  },
  mutations: {
    set_accion_indicadorMensual (state, indicadorMensual) {
      state.indicadorMensual = indicadorMensual
    },
    set_accion_sitiosLosHeores (state, sitiosLosHeores) {
      state.sitiosLosHeores = sitiosLosHeores
    }
  },
  actions: {
    async getIndicadorMensual ({ commit, dispatch, state }, mes) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.hostAws}/iss-asistencia/api/reporte/indicador-mensual?mes=${mes}`
        })
        if (response.status === 200) {
          console.log('success stuff api getIndicadorMensual')
          console.log(response.data)
          commit('set_accion_indicadorMensual', response.data)
          return true
        } else {
          console.log('por else getIndicadorMensual')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getIndicadorMensual')
        // router.push({ path: '/' })
        return false
      }
    },
    async getSitiosLosHeores ({ commit, dispatch, state }, mes) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.hostAws}/iss-asistencia/api/reporte/sitios-los-heroes?mes=${mes}`
        })
        if (response.status === 200) {
          console.log('success stuff api getSitiosLosHeores')
          console.log(response.data)
          commit('set_accion_sitiosLosHeores', response.data)
          return true
        } else {
          console.log('por else getSitiosLosHeores')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getSitiosLosHeores')
        // router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
