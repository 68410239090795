import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    dotaciones: [],
    acreditados: [],
    asistencias: []
  },
  mutations: {
    set_accion_dotaciones (state, dotaciones) {
      state.dotaciones = dotaciones
    },
    set_accion_acreditados (state, acreditados) {
      state.acreditados = acreditados
    },
    set_accion_asistencias (state, asistencias) {
      state.asistencias = asistencias
    }
  },
  actions: {
    async setDotaciones ({ commit, dispatch, state }, file) {
      try {
        const response = await axios({
          method: 'post',
          url: `http://${store.state.host}/api/iss/files/excelDotacionMultipart`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: file
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api setDotaciones')
          console.log(response.data)
          commit('set_accion_dotaciones', response.data)
          return true
        } else {
          console.log('por else setDotaciones')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch setDotaciones')
        router.push({ path: '/' })
        return false
      }
    },
    async setAcreditados ({ commit, dispatch, state }, file) {
      try {
        const response = await axios({
          method: 'post',
          url: `http://${store.state.host}/api/iss/files/excelAcreditadosMultipart`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: file
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api setAcreditados')
          console.log(response.data)
          commit('set_accion_acreditados', response.data)
          return true
        } else {
          console.log('por else setAcreditados')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch setAcreditados')
        router.push({ path: '/' })
        return false
      }
    },
    async setAsistencias ({ commit, dispatch, state }, file) {
      try {
        const response = await axios({
          method: 'post',
          url: `http://${store.state.host}/api/iss/files/excelAsistenciaPersonalMultipart`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: file
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api setAsistencias')
          console.log(response.data)
          commit('set_accion_asistencias', response.data)
          return true
        } else {
          console.log('por else setAsistencias')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch setAsistencias')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
