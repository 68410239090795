<script>
// Importing Line class from the vue-chartjs wrapper
import { Pie } from 'vue-chartjs'

// Exporting this so it can be used in other components
export default {
  extends: Pie,
  props: {
    render: null,
    Labels: null,
    Datasets: null,
    BackgroundColor: null
  },
  data () {
    return {
      datacollection: {
        // Data to be represented on x-axis
        labels: [],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: [
              // '#FF0000',
              // 'rgb(255, 255, 0)',
              // 'rgb(218, 247, 166)'
              // 'rgb(128, 128, 0)'
            ],
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            // Data to be represented on y-axis
            data: []
          }
          // {
          //   label: 'Data One',
          //   backgroundColor: '#f87979',
          //   pointBackgroundColor: 'white',
          //   borderWidth: 1,
          //   pointBorderColor: '#249EBF',
          //   // Data to be represented on y-axis
          //   data: [40, 20, 30, 50, 90, 10, 20, 40, 50, 70, 90, 100]
          // },
          // {
          //   label: 'Data One',
          //   backgroundColor: '#f87979',
          //   pointBackgroundColor: 'white',
          //   borderWidth: 1,
          //   pointBorderColor: '#249EBF',
          //   // Data to be represented on y-axis
          //   data: [40, 20, 30, 50, 90, 10, 20, 40, 50, 70, 90, 100]
          // }
        ]
      },
      // Chart.js options that controls the appearance of the chart
      options: {
        scales: {
          // yAxes: [{
          //   ticks: {
          //     beginAtZero: true
          //   },
          //   gridLines: {
          //     display: true
          //   }
          // }],
          // xAxes: [{
          //   gridLines: {
          //     display: false
          //   }
          // }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    // renderChart function renders the chart with the datacollection and options object.
    // this.chart.update()
    console.log('se crear pie')
    this.datacollection.labels = this.Labels
    this.datacollection.datasets[0].data = this.Datasets
    this.datacollection.datasets[0].backgroundColor = this.BackgroundColor
    this.renderChart(this.datacollection, this.options)
    this.$emit('emitRender', true)
  },
  watch: {
    render: {
      handler: function (val, oldVal) {
        console.log('render ')
        // console.log(this.render)
        // console.log(this.Datasets)
        this.datacollection.labels = this.Labels
        this.datacollection.datasets[0].data = this.Datasets
        this.datacollection.datasets[0].backgroundColor = this.BackgroundColor
        this.renderChart(this.datacollection, this.options)
      }
    }
  }
}
</script>
